/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, SVGAttributes } from "react";

export const AeiLogo = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((properties, reference) => (
  <svg
    fill="currentColor"
    ref={reference}
    viewBox="0 0 95.3 48"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="m56.6 8.3-1.1.1-.9.2-.9.3-.4.3-.4.2-.3.3-.4.4-.3.4-.3.5-.5 1.1 1.8-7.3.3-.8.4-.9.4-.6.4-.6.6-.4.5-.4.5-.3.6-.3.7-.2.6-.1 1.5-.1 1.5-.1h19.4l-1.9 8.2H57.8zm-2.7 12.2-1 .1-.9.2-.9.3-.4.3-.5.2-.4.3-.3.4-.3.4-.3.5-.3.5-.4.6L50 17l.4-.8.4-.8.3-.7.5-.6.5-.4.6-.4.5-.3.6-.2.6-.3.7-.1 1.5-.1 1.4-.1h19.4l-1.9 8.3H55zm-.2 3.8-1.4.2-.7.2-.6.2-.6.2-.6.3-.6.4-.4.5-.5.6-.4.6-.4.8-.3.9-1.2 4.7-.3.9-.5.7-.6.6-.3.2-.3.2-.9.3-.9.2-1.3.1H12.7l-1 .1-.8.1-.9.2-.9.4-.9.4-.8.5-.7.6-.6.7L0 48l.9-.8.9-.7.4-.2.5-.3 1-.3 1.3-.2 1.5-.1h27.8l3.4-.1h1.6l.6-.1.7-.2.6-.1.6-.3.6-.3.5-.4.5-.6.4-.6.3-.7.3-.8.5-2.4.5-1.8.3-1.2.3-.5.2-.4.3-.4.3-.4.4-.3.4-.2.8-.4.8-.3 1-.2h.9l1-.1h20.6l1.9-8.3H55.2zM86.4 0h8.9l-7.6 32.6h-9z" />
    <path d="m30 20.1 7.5-10.3-2.8 10.3H30ZM36.6 0 11.3 32.6h10.1l3.4-4.7H33l-1.1 4.7H41L48.4 0H36.6Z" />
  </svg>
));
