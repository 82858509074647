"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useIntl } from "react-intl";
import { AeiLogo } from "@/components/aei-logo/aei-logo";
import { CsisLogo } from "@/components/csis-logo/csis-logo";
import { SpearAiLogo } from "@/components/spear-ai-logo/spear-ai-logo";

export const PartnerSection = () => {
  const intl = useIntl();

  const content = (
    <>
      <li className="flex h-12 items-center text-neutral-12">
        <AeiLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <CsisLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <SpearAiLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <AeiLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <CsisLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <SpearAiLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <AeiLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <CsisLogo className="h-full w-auto" />
      </li>
      <li className="flex h-12 items-center text-neutral-12">
        <SpearAiLogo className="h-full w-auto" />
      </li>
    </>
  );

  return (
    <section className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="group flex space-x-16 overflow-hidden">
          <ul className="flex animate-scroll space-x-16 rtl:direction-reverse">{content}</ul>
          <ul aria-hidden="true" className="flex animate-scroll space-x-16 rtl:direction-reverse">
            {content}
          </ul>
        </div>
        <div className="mt-16 flex justify-center">
          <p className="relative rounded-full border-1 border-neutral-a-7 bg-white-a-9 px-4 py-1.5 text-sm leading-6 text-neutral-11 dark:bg-white-a-1">
            {intl.formatMessage(
              {
                defaultMessage: "{description} {readMore}",
                id: "zKuc1m",
              },
              {
                description: (
                  <span className="hidden md:inline">
                    {intl.formatMessage({
                      defaultMessage: "Organizations use our tool to plan their defense budget.",
                      id: "dEPQre",
                    })}
                  </span>
                ),
                readMore: (
                  <Link
                    className="inline-block rounded-md font-semibold text-primary-12"
                    href="https://www.aei.org/defense-futures-simulator"
                    rel="noopener"
                    target="_blank"
                  >
                    {intl.formatMessage({ defaultMessage: "Read more", id: "S++WdB" })}
                    <ArrowRightIcon
                      aria-hidden="true"
                      className="relative inline-block size-5 text-inherit -rotate-45 rtl:rotate-45 rtl:-scale-x-100"
                    />
                  </Link>
                ),
              },
            )}
          </p>
        </div>
      </div>
    </section>
  );
};
