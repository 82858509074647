/* eslint-disable tailwindcss/no-arbitrary-value */

"use client";

import Link from "next/link";
import { useIntl } from "react-intl";
import { CircleAccent } from "@/components/circle/circle-accent";

export const HeroSection = () => {
  const intl = useIntl();
  return (
    <div className="relative overflow-hidden py-10">
      <div className="grid-background" />
      <div className="sphere absolute bottom-[20%] right-[70%]  opacity-20" />
      <div className="sphere sphere1 absolute bottom-[10%] right-[10%]  opacity-20" />
      <div className="sphere sphere2 absolute bottom-[5%] right-[50%] opacity-20" />
      <div className="sphere sphere3 absolute bottom-[45%] right-[3%] opacity-20" />
      <div className="relative mx-auto max-w-7xl px-4 pb-16 text-center sm:px-6 lg:px-8">
        <div className="mb-8 flex size-full items-center justify-center" />
        <h1 className="mx-auto max-w-4xl text-4xl font-bold leading-none tracking-[-0.1rem] text-neutral-12 sm:text-5xl md:text-7xl">
          {intl.formatMessage(
            {
              defaultMessage: "Build the {optimal} military within your resources",
              id: "ZZQkIE",
            },
            {
              optimal: (
                <span className="relative inline-block">
                  <CircleAccent className="absolute left-[-7%] top-[-110%] z-[-10] w-[115%] text-primary-dark-9" />
                  <span className="relative text-neutral-12">
                    {intl.formatMessage({
                      defaultMessage: "optimal",
                      id: "crfNER",
                    })}
                  </span>
                </span>
              ),
            },
          )}
        </h1>
        <p className="mx-auto mt-6 max-w-2xl text-lg font-normal tracking-tight text-neutral-12 md:text-xl">
          {intl.formatMessage(
            {
              defaultMessage:
                "Empower Your defense strategy. Simulate, strategize, and succeed with {title}.",
              id: "Lpqagd",
            },
            {
              title: (
                <b className="whitespace-nowrap font-bold">
                  {intl.formatMessage({
                    defaultMessage: "Defense Futures Simulator",
                    id: "KLcY/2",
                  })}
                </b>
              ),
            },
          )}
        </p>
        <div className="mt-10 flex justify-center gap-x-6">
          <Link
            className="relative rounded-md bg-primary-dark-9 px-3.5 py-2.5 text-sm font-semibold text-primary-contrast shadow-sm hover:bg-primary-dark-10"
            href="/sign-up"
          >
            <span>
              {intl.formatMessage({
                defaultMessage: "Get started",
                id: "/aBLH2",
              })}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
